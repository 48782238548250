import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as s from './footer.scss'
import Facebook from '../../assets/svg/facebook.svg'
import Instagram from '../../assets/svg/instagram.svg'
import MakeAWish from '../../assets/svg/make-a-wish.svg'

const FOOTER_QUERY = graphql`
    query FooterQuery {
        allPrismicSocialLinks {
            nodes {
                data {
                    facebook_url {
                        url
                    }
                    instagram_url {
                        url
                    }
                }
            }
        }
    }
`

const Footer = () => {
    const {
        allPrismicSocialLinks: { nodes },
    } = useStaticQuery(FOOTER_QUERY)
    return (
        <footer className={s.footer}>
            <div className={s.footer__wrapper}>
                <div className={s.footer__social}>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={nodes[0].data.facebook_url.url}
                    >
                        <Facebook />
                    </a>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={nodes[0].data.instagram_url.url}
                    >
                        <Instagram />
                    </a>
                </div>
                <div className={s.footer__proceeds}>
                    <p>All Proceeds Benefit</p>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://nebraska.wish.org/"
                    >
                        <MakeAWish />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
