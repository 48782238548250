import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import * as s from './header.scss'

const HEADER_QUERY = graphql`
    query HeaderQuery {
        HeaderLogo: file(relativePath: { eq: "WWW-W.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const Header = ({ siteTitle }) => {
    const { HeaderLogo } = useStaticQuery(HEADER_QUERY)

    return (
        <header className={`${s.header} test`}>
            <div className={s.header__inner}>
                <Link to="/">
                    <Img
                        fluid={HeaderLogo.childImageSharp.fluid}
                        className={s.header__logo}
                    />
                </Link>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
